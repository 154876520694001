<template>
  <div class="settlement-report-container">
    <a-table
      class="m_table_cust"
      size="middle"
      :pagination="false"
      :rowKey="(record, index) => index"
      :columns="columns"
      :data-source="dataList"
      tableLayout="fixed"
      :loading="isLoading"
      bordered
    >
      <span slot="type" slot-scope="type">
        {{ +type === 1 ? '媒体广告' : '' }}
      </span>
      <span slot="sts" slot-scope="sts">
        <a-tag :color="+sts === 1 ? 'orange' : +sts === 2 ? 'red' : +sts === 3 ? 'green' : ''">
          {{ +sts === 1 ? '待同步' : +sts === 2 ? '待确认' : +sts === 3 ? '已确认' : '' }}
        </a-tag>
      </span>
      <div slot="progress" slot-scope="progress, record">
        <a-select
          size="small"
          class="search_item"
          placeholder="请选择支付进度状态"
          v-model="record.progress"
          @change="changeProgress($event, record)"
          style="width: 110px"
          v-if="+isAdmin !== 2"
        >
          <a-select-option value="1">发票待提交</a-select-option>
          <a-select-option value="2">发票审核中</a-select-option>
          <a-select-option value="3">付款中</a-select-option>
          <a-select-option value="4">已付款</a-select-option>
        </a-select>
        <span v-else>{{
          +progress === 1
            ? '发票待提交'
            : +progress === 2
              ? '发票审核中'
              : +progress === 3
                ? '付款中'
                : +progress === 4
                  ? '已付款'
                  : ''
        }}</span>
      </div>
      <div slot="action" slot-scope="record">
        <a-button class="action_btn" v-if="+record.sts === 1 && +isAdmin !== 2" size="small" @click="changeSts(record)">
          同步金额
        </a-button>
        <a-button v-else-if="+record.sts === 2 && +isAdmin === 2" size="small" @click="changeSts(record)">
          确认金额
        </a-button>
        <a-tag color="red" v-else-if="+record.sts === 2 && +isAdmin !== 2">金额待确认</a-tag>
        <a-tag color="green" v-else>金额已确认</a-tag>
      </div>
    </a-table>
    <a-pagination
      class="pagination"
      v-if="dataList.length > 0"
      show-size-changer
      :current="query.page"
      :total="total"
      @change="handleChangePage"
      :show-total="(total) => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange"
      :pageSize="query.limit"
    />
    <m-empty v-else style="height: 70vh; width: 100%">
      <template #description> 暂无数据 </template>
    </m-empty>
  </div>
</template>

<script>
import { getSettlementPage, settlementUpdate } from '@/api/financialManagementA'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      query: {
        dateStart: '',
        dateEnd: '',
        userId: '',
        sts: '',
        progress: '',
        name: '',
        page: 1,
        limit: 10
      },
      total: 0,
      dataList: [],
      isLoading: false,
      allColumns: [
        {
          title: '业务时间',
          dataIndex: 'date',
          width: '150px',
          scopedSlots: {
            customRender: 'date'
          }
        },
        {
          title: '业务类型',
          dataIndex: 'type',
          width: '150px',
          scopedSlots: {
            customRender: 'type'
          }
        },
        {
          title: '公司名称',
          dataIndex: 'company',
          width: '150px',
          scopedSlots: {
            customRender: 'company'
          }
        },
        {
          title: '账户名称',
          dataIndex: 'name',
          width: '150px',
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          title: '',
          dataIndex: 'amount',
          width: '150px',
          scopedSlots: {
            customRender: 'amount'
          }
        },
        {
          title: '金额状态',
          dataIndex: 'sts',
          width: '150px',
          scopedSlots: {
            customRender: 'sts'
          }
        },
        {
          title: '支付进度',
          dataIndex: 'progress',
          width: '150px',
          scopedSlots: {
            customRender: 'progress'
          }
        },
        {
          title: '操作',
          width: '150px',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      columns: []
    }
  },
  created () {
    if (+this.isAdmin === 2) {
      this.columns = this.allColumns.filter((item) => item.dataIndex !== 'name')
    } else {
      this.columns = this.allColumns
    }
    this.columns.forEach((item) => {
      if (item.dataIndex === 'amount') {
        item.title = +this.isAdmin === 2 ? '收入金额' : '支出金额'
      }
    })
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin,
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title
    })
  },
  methods: {
    async changeProgress (e, record) {
      const resp = await settlementUpdate({
        id: record.id,
        sts: record.sts,
        progress: e
      })
      if (resp.code === 200) {
        this.$message.success('修改成功！')
        this.getDataList()
      }
    },
    async changeSts (record) {
      const resp = await settlementUpdate({
        id: record.id,
        sts: +this.isAdmin !== 2 ? '2' : '3',
        process: record.progress
      })
      if (resp.code === 200) {
        this.$message.success('修改成功！')
        this.getDataList()
      }
    },
    handleSearch (val) {
      this.query = {
        ...val,
        page: 1,
        limit: this.query.limit
      }
      this.getDataList()
    },
    async getDataList () {
      this.isLoading = true
      const resp = await getSettlementPage(this.query)
      this.total = resp.data ? resp.data.total : 0
      this.dataList = resp.data ? resp.data.items : []
      this.isLoading = false
    },
    handleChangePage (page) {
      this.query.page = page
      this.getDataList()
    },
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.settlement-report-container {
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;

  .pagination {
    margin: 10px;
  }
}
</style>
<style lang="less">
.action_btn {
  border-color: orange;
  color: orange;
}
</style>
