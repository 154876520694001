<template>
  <div class="settlement-manager-container">
    <SettlementSearch @handleAutoSearch="handleAutoSearch" />
    <SettlementReport ref="report" />
  </div>
</template>

<script>
import SettlementSearch from './SettlementSearch'
import SettlementReport from './SettlementReport'
export default {
  name: 'FunnelReport',
  components: { SettlementSearch, SettlementReport },
  data () {
    return {
      query: {}
    }
  },
  methods: {
    handleAutoSearch (val) {
      this.query = JSON.parse(JSON.stringify(val))
      this.$nextTick(() => {
        this.$refs.report.handleSearch(this.query)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.settlement-manager-container {
  margin: 0 10px 10px 10px;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
}
</style>
