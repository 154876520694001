<template>
  <div class="money-container">
    <div class="money_table">
      <div>
        <a-spin :spinning="loading">
          <a-table
            class="m_table_cust"
            :pagination="false"
            :columns="columns"
            :data-source="dataList"
            :rowKey="
              (record, index) => {
                return index
              }
            "
            bordered
          >
            <!-- <div slot="accounts">直客</div> -->
            <template slot="action" slot-scope="text, record">
              <a-button size="small" @click="handleClickUpdatePlace(record)">充值记录</a-button>
            </template>
          </a-table>
          <a-pagination
            class="pagination"
            v-if="dataList.length > 0"
            show-size-changer
            :current="query.page"
            :total="total"
            :pageSize="query.limit"
            :show-total="(total) => `共 ${total} 条`"
            @change="handleChangePage"
            @showSizeChange="onShowSizeChange"
          />
        </a-spin>
      </div>
    </div>

    <MoneyManagement
      v-if="addPlaceVisible"
      :placeInfo="placeInfo"
      :visible="addPlaceVisible"
      @modalCancel="addPlaceVisible = false"
      @changePlace="changePlace"
      :dialog-style="{ width: '800px' }"
    />
  </div>
</template>

<script>
import { capitalListPage } from '@/api/financialManagementA'
import MoneyManagement from '../components/moneyManagement'
import { numFormat } from '@/utils/dealNumber'

// import mixDate from '@/mixins/initDate'
const columns = [
  {
    dataIndex: 'partyName',
    title: '公司名称',
    key: 'partyName',
    align: 'left',
    customRender: (text, record, index) => {
      return record.partyName || '-'
    }
  },
  {
    dataIndex: 'partyType',
    title: '账户类型',
    key: 'partyType',
    align: 'left',
    customRender: (text, record, index) => {
      if (record.partyType === '1') {
        text = '广告主'
      } else if (record.partyType === '2') {
        text = '代理商'
      } else if (record.partyType === '3') {
        text = '广告平台'
      } else if (record.partyType === '4') {
        text = '平台媒体'
      }
      return text
    }
  },

  {
    dataIndex: 'zijinAccountNo',
    title: '账号',
    key: 'zijinAccountNo',
    align: 'left'
  },
  // {
  //   dataIndex: 'agentName',
  //   title: '代理公司',
  //   key: 'agentName',
  //   align: 'left'
  // },
  // {
  //   dataIndex: 'username',
  //   title: '客户经理',
  //   key: 'username',
  //   align: 'left'
  // },
  {
    dataIndex: 'zijinIncomeAmount',
    title: '账户总存入金额',
    key: 'zijinIncomeAmount',
    align: 'left',
    customRender: (text, record, index) => {
      text =
        record.zijinIncomeAmount || record.zijinIncomeAmount === 0 ? numFormat(record.zijinIncomeAmount, 3, '') : '-'
      return text
    }
  },
  {
    dataIndex: 'zijinRemainAmount',
    title: '账户资金余额',
    key: 'zijinRemainAmount',
    align: 'left',
    customRender: (text, record, index) => {
      text =
        record.zijinRemainAmount || record.zijinRemainAmount === 0 ? numFormat(record.zijinRemainAmount, 3, '') : '-'
      return text
    }
  },
  {
    dataIndex: 'zijinExpenseAmount',
    title: '账户资金支出金额',
    key: 'zijinExpenseAmount',
    align: 'left',
    customRender: (text, record, index) => {
      text =
        record.zijinExpenseAmount || record.zijinExpenseAmount === 0 ? numFormat(record.zijinExpenseAmount, 3, '') : '-'
      return text
    }
  },
  {
    dataIndex: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'left'
  }
]
export default {
  name: 'Money',
  components: { MoneyManagement },
  data () {
    return {
      bodyStyle: {
        width: 800
      },
      partyType: undefined,
      incomeModel: undefined,
      partyId: undefined,
      partyName: '',
      contractType: undefined,
      invoiceSts: undefined,
      contractStsPush: [
        {
          value: '未归档',
          table: 0
        },
        {
          value: '归档',
          table: 1
        }
      ],
      contractTypePush: [
        {
          value: '媒体和代理',
          table: 1
        },
        {
          value: '媒体和广告主',
          table: 2
        }
      ],
      contractNo: '',
      columns,
      addPlaceVisible: false,
      placeInfo: {},
      supplier: 1,
      placeView: {},
      loading: false,
      addAbtestVisible: false,
      addGroupVisible: false,
      appList: [],
      placeList: [],
      industry: [],
      currentGroup: [],
      groupList: [],
      mode: 'inline',
      theme: 'light',
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      BusNames: { label: 'name', value: 'id' },
      pid: [],
      query: {
        page: 1,
        limit: 10
      },
      total: 0,
      businessUser: [],
      pidBus: [],
      dateStrings: [],
      dateString: [],
      manager: undefined,
      dataList: [],
      times: [],
      advertiserIndustry: '',
      businessUserPush: '',
      keys: '',
      types: ''
    }
  },
  computed: {},
  mounted () {
    this.getCapitalListPage()
  },
  watch: {
    handler (val) {
      if (val.tradePid && val.tradeId) {
        this.pid = [val.tradePid, val.tradeId]
        this.query = JSON.parse(JSON.stringify(val))
      }
    }
  },
  methods: {
    handleClick (e) {
      this.keys = e.key
      this.getCapitalListPage()
    },
    changeMode (checked) {
      this.mode = checked ? 'vertical' : 'inline'
    },
    changeTheme (checked) {
      this.theme = checked ? 'dark' : 'light'
    },

    async getCapitalListPage () {
      this.loading = true
      this.dataList = []
      const res = await capitalListPage(this.query)
      if (res.code === 200) {
        this.loading = false
        this.dataList = res.data.list || []
        this.total = res.data.total
      }
    },
    changeTradeId (val) {},
    changeBusiness (val) {},

    // 修改
    handleClickUpdatePlace (record) {
      this.placeInfo = record
      this.addPlaceVisible = true
    },
    changePlace () {
      this.getCapitalListPage()
    },

    // 分页
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.timer = setTimeout(() => {
        this.getCapitalListPage()
      }, 1000)
    },
    handleChangePage (page) {
      this.query.page = page
      this.timer = setTimeout(() => {
        this.getCapitalListPage()
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.money-container {
  padding: 0 10px;
  .money_table {
    padding: 20px;
    height: 100%;
    min-height: 85vh;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
    .pagination {
      padding-top: 20px;
    }
  }
}
</style>
