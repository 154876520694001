<template>
  <a-modal v-model="addPlaceView" :title="this.names" :closable="false" width="900px">
    <div>
      <a-spin :spinning="loading">
        <a-table
          class="m_table_cust"
          :pagination="false"
          :columns="columns"
          :data-source="dataList"
          :rowKey="
            (record, index) => {
              return index
            }
          "
          bordered
        >
        </a-table>
        <m-empty style="min-height: 40vh" v-if="dataList.length === 0">
          <template #description> 暂无数据 </template>
        </m-empty>
        <a-pagination
          class="pagination"
          v-if="dataList.length > 0"
          show-size-changer
          :current="query.page"
          :show-total="(total) => `共 ${total} 条`"
          :total="total"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
        />
      </a-spin>
    </div>
    <template slot="footer">
      <a-button @click="handleDown"> 关闭 </a-button>
    </template>
  </a-modal>
</template>

<script>
import { incomeListPage } from '@/api/financialManagementA'
import { mapState } from 'vuex'
import { numFormat } from '@/utils/dealNumber'
const columns = [
  {
    dataIndex: 'partyName',
    title: '公司名称',
    key: 'partyName',
    align: 'center'
  },
  {
    dataIndex: 'partyType',
    title: '账户类型',
    key: 'partyType',
    align: 'center',
    customRender: (text, record, index) => {
      if (record.partyType === '1') {
        text = '广告主'
      } else if (record.partyType === '2') {
        text = '代理商'
      } else if (record.partyType === '3') {
        text = '广告平台'
      } else if (record.partyType === '4') {
        text = '平台媒体'
      }
      return text
    }
  },
  {
    dataIndex: 'addTime',
    title: '充值时间',
    key: 'addTime',
    align: 'center'
  },
  {
    dataIndex: 'incomeAmount',
    title: '充值金额',
    key: 'incomeAmount',
    align: 'center',
    customRender: (text, record, index) => {
      text = record.incomeAmount || record.incomeAmount === 0 ? numFormat(record.incomeAmount, 3, '') : '-'
      return text
    }
  },
  {
    dataIndex: 'incomeModel',
    title: '收款方式',
    key: 'incomeModel',
    align: 'center',
    customRender: (text, record, index) => {
      if (record.incomeModel === '1') {
        text = '预付费'
      } else if (record.incomeModel === '2') {
        text = '后付费'
      }
      return text
    }
  },
  {
    dataIndex: 'incomeSts',
    title: '收入状态',
    key: 'incomeSts',
    align: 'center',
    customRender: (text, record, index) => {
      if (record.incomeSts === '0') {
        text = '未到账'
      } else if (record.incomeSts === '1') {
        text = '已到账'
      }
      return text
    }
  }
]
export default {
  data () {
    return {
      dataList: [],
      columns,
      tps: 1,
      datts: [],
      defectSearchForm: {},
      imagesUrl: '',
      url: '...',
      fileList: [],
      sysAgent: [],
      businessUser: [],
      industry: [],
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      pid: [],
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {},
      appList: [],
      positionList: [],
      templateList: [],
      names: '',
      companyTypes: ''
    }
  },
  props: {
    isAdd: {
      default: '',
      type: String
    },
    visible: {
      type: Boolean,
      default: false
    },
    appId: {
      default: '',
      type: String
    },
    placeInfo: {
      type: [Boolean, Object],
      default: () => ({})
    }
  },
  watch: {
    placeInfo: {
      handler (val) {
        this.names = '充值记录'
        this.query = JSON.parse(JSON.stringify(val))
      },
      deep: true,
      immediate: true
    },
    appId: {
      handler (val) {
        if (val) {
          this.query.appId = val
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.getincomeListPage()
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    addPlaceView: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    async getincomeListPage () {
      this.loading = true
      this.dataList = []
      var pust = {
        partyType: this.query.partyType,
        partyId: this.query.partyId
      }
      const res = await incomeListPage(pust)
      if (res.code === 200) {
        this.loading = false
        this.dataList = res.data.list || []
        this.total = res.data.total
      }
    },
    handleDown () {
      this.addPlaceView = false
    },
    // 分页
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.timer = setTimeout(() => {
        this.getincomeListPage()
      }, 1000)
    },
    handleChangePage (page) {
      this.query.page = page
      this.timer = setTimeout(() => {
        this.getincomeListPage()
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.ant-modal {
  width: 800px !important;
  .pagination {
    margin-top: 10px;
  }
}
</style>
